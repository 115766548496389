import React, { Component } from 'react';

class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoSmall: false
        }
    }
    componentDidMount() {
        if (typeof window !== `undefined`) {
            window.addEventListener("resize", this.resize.bind(this));
            this.resize();

            window.addEventListener("scroll", this.resizeScroll.bind(this));
            this.resizeScroll();
        }
    }
    resize() {
        let logoSize = (window.innerWidth <= 1170);
        if (logoSize !== this.state.logoSmall) {
            this.setState({logoSmall: !this.state.logoSmall});
            this.resizeScroll();
        }
    }
    resizeScroll() {
        if(this.props.scrollStatus) {
            this.setState({logoSmall: true});
        }else{
            this.setState({logoSmall: false});
            if(window.innerWidth <= 1170) {
                this.setState({logoSmall: !this.state.logoSmall});
            }
        }
    }
    render() {
        return (
            <>
            { !this.state.logoSmall && 
                <img src={ this.props.data } alt="Prinz Stefan" />
            }
            { this.state.logoSmall && 
                <img src={ this.props.smallLogo } alt="Prinz Stefan" />
            }
            </>
        )
    }
}

export default Logo;