import React, { useState, useEffect } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Logo from './../logo/Logo';

const Navigation = ( props ) =>  {
    const [ active, setActive ] = useState('home');
    const [ load, setLoad ] = useState(0);
    const [ bgMenu, setBgMenu ] = useState(false);
    let pixels = [];
    let names = [];
    if(typeof window !== 'undefined') {
      window.addEventListener('scroll', function() {
        if(window.scrollY >= 100) {
          setBgMenu(true);
        }else{
          setBgMenu(false);
        }
      })
      window.addEventListener('scroll', function() {
        pixels.forEach((el,index) => {
          if( window.scrollY >= el - 300 ) {
            setActive(names[index]);
          }
        })
      })
      window.onresize = function() {
        let data = []
        let namesTemp = [];
        let items = document.querySelectorAll('.menu li');
        items.forEach( el => {
          let getScrollTop = document.getElementById(el.dataset.id);
          if ( getScrollTop ) {
            data = [...data, getScrollTop.offsetTop];
            namesTemp = [...namesTemp, el.dataset.id]
          }
        });
        pixels = data;
        names = namesTemp;
        setLoad(1);
      }
    }
    

    useEffect(() => {
      if (load === 0) {
        let data = []
        let namesTemp = [];
        let items = document.querySelectorAll('.menu li');
        items.forEach( el => {
          let getScrollTop = document.getElementById(el.dataset.id);
          if ( getScrollTop ) {
            data = [...data, getScrollTop.offsetTop];
            namesTemp = [...namesTemp, el.dataset.id]
          }
        });
        pixels = data;
        names = namesTemp;
        setLoad(1);
      }
      
    });

    return (
        <nav className={bgMenu ? "scrollActive" : ""}>
            <ul className="menu">
                { props.data.items.map( (el, i) => (
                    <>
                        { props.data.items.length / 2 === i && 
                            <li key={ i + 10 } className="logo">
                                <Logo scrollStatus={ bgMenu } data={ props.logo } smallLogo={ props.smallLogo } />
                            </li>
                        }
                    
                        <li key={ i } data-id={ el.slug.replace(" ", "-").toLowerCase() } >
                            <AnchorLink offset='100'
                                        href={`#${ el.slug }`}
                                        className={ (el.slug == active ? 'active' : '') }>
                                        { el.title }
                            </AnchorLink>
                        </li>
                    </>
                )) }
            </ul>
        </nav>
    )
}

export default Navigation;