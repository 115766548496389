import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "./Navigation"

export default props => {
  const data = useStaticQuery(graphql`
    query ContactDataQuery {
      menu: allWordpressWpApiMenusMenusItems(
        filter: { wordpress_id: { eq: 2 } }
      ) {
        edges {
          node {
            wordpress_id
            name
            items {
              title
              slug: object_slug
            }
          }
        }
      }
    }
  `)

  return <Navigation logo={ props.logo } smallLogo={ props.smallLogo }  data={data.menu.edges[0].node} active={props.active} />
}
