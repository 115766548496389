import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Copyright from './copyright/Copyright';

const Footer = (props) => {

  const data = useStaticQuery(graphql`
  query FooterDataQuery {

    copyright:wordpressAcfOptions {
        wordpress_id
        options {
            footer_text
        } 
    }
    
  }
  `)

return(
    <div className="container">
          <Copyright data={data.copyright.options} />
    </div>
  )
}

export default Footer;