import React from 'react';

const Copyright = ( props ) => (
    
    <div className="copyright-box">
        <span>{ props.data.footer_text }</span>
    </div>
)


export default Copyright;