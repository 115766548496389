import React from "react"
import "./../scss/main.scss"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header/Header"
import Footer from "./Footer/Footer"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        allWordpressAcfOptions {
          nodes {
            options {
              footer_text
              logo_in_menu {
                localFile {
                  publicURL
                }
              }
              logo_in_menu_mobile {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={ data => (
      <>
        <Header logo={ data.allWordpressAcfOptions.nodes[0].options.logo_in_menu.localFile.publicURL } 
                smallLogo={ data.allWordpressAcfOptions.nodes[0].options.logo_in_menu_mobile.localFile.publicURL } />

          <main>{children}</main>
          <footer>
            <Footer />
          </footer>
      </> )} />
  )


export default Layout
